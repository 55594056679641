@import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';

/*--------------------------------------------------------------
# Webkit-Scrollbar Styles
--------------------------------------------------------------*/

#rootclient {
    --primary-color: #202123;
    --secondary-color: #45494c;
    --chat-bg: #141519;
    --btn-bg: #f5f5f5;
    --video-hf-bg: rgba(0, 0, 0, 0.5);
    --btn-color: #000000;
    --text-color: #f5f5f5;
    --hover-color: #45494c;
    --border: 0.1px solid #45494c;
    --border-radius: 1rem;
    --video-obj-fit: cover;
    --my-video-wrap-width: 360px; /* Safari */
    --my-video-wrap-height: 205px;
    --settings-width: 280px;
    --chat-width: 320px;
    --btn-hover-scale: scale(1.1);
    /*position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 4;*/
}

* {
    font-family: 'Comfortaa';
    outline: none;
}

/*--------------------------------------------------------------
# Body Html
--------------------------------------------------------------*/
#bodyclient {

    overflow: hidden;
    background: #202123;
    user-select: none;
    position: fixed;
    
    top: 0 !important;
    width: 100%;
    height: 100%;
    margin: 0 0 0 0;
    /*left: 0;
    z-index: 4;
    padding: 0;
    */

    
}

/*--------------------------------------------------------------
# Loading Div... 
--------------------------------------------------------------*/

#loadingDiv {
    min-width: 290px;
    padding: 30px;
    border: var(--border);
    border-radius: var(--border-radius);
    background: var(--secondary-color);
    color: #ffffff;
}

#loadingDiv h1 {
    font-size: 60px;
}

#loadingDiv pre {
    padding: 5px;
    font-size: 15px;
}

/*--------------------------------------------------------------
# Room QR
--------------------------------------------------------------*/

#qrRoomContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

/*--------------------------------------------------------------
# Main buttons
--------------------------------------------------------------*/

#buttonsBar {
    position: fixed;
    z-index: 6;
    padding: 10px;
    width: 100%;
    bottom: 0;
    display: none;
    text-align: center;
    border-radius: 20px;
    background: transparent;
}

#buttonsBar button {
    width: 2.5rem;
    height: 2.5rem;
    padding: 10px;
    border: none;
    border-radius: 50px;
    background: var(--btn-bg);
    color: var(--btn-color);
    text-align: center;
    font-size: 1.1rem;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

#buttonsBar button:hover {
    color: var(--hover-color);
    transform: var(--btn-hover-scale);
    -webkit-transform: var(--btn-hover-scale);
    -moz-transform: var(--btn-hover-scale);
}

#buttonsBar button:focus {
    outline: none;
}

.fa-user-slash,
.fa-microphone-slash,
.fa-video-slash {
    color: #e74c3c !important;
}

/*--------------------------------------------------------------
# Video Wrap - Header - Footer
--------------------------------------------------------------*/

.myVideoWrap {
    position: absolute;
    z-index: 5;
    top: 10px;
    left: 10px;
    width: var(--my-video-wrap-width);
    height: var(--my-video-wrap-height);
    border: var(--border);
    border-radius: var(--border-radius);
    overflow: hidden;
}

.remoteVideoWrap {
    z-index: 4;
    width: 100vw;
    height: 100vh;
    float: left;
    overflow: hidden;
}

.videoHeader {
    position: absolute;
    z-index: 2;
    top: 5px;
    right: 10px;
    overflow: hidden;
    width: auto;
    padding: 5px;
    border-radius: var(--border-radius);
    font-size: small;
    font-weight: bold;
    text-align: right;
    background: var(--video-hf-bg);
    cursor: default;
}

.videoHeader button {
    display: inline;
    border: none;
    background: transparent;
    color: var(--text-color);
}

.videoFooter {
    position: absolute;
    z-index: 3;
    left: 10px;
    bottom: 5px;
    overflow: hidden;
    width: auto;
    padding: 5px;
    border-radius: var(--border-radius);
    background: var(--video-hf-bg);
    font-size: small;
    font-weight: bold;
    text-align: left;
    cursor: default;
}

.remoteVideoFooter {
    position: absolute;
    z-index: 3;
    right: 10px;
    bottom: 5px;
    overflow: hidden;
    width: auto;
    padding: 5px;
    border-radius: var(--border-radius);
    background: var(--video-hf-bg);
    font-size: small;
    font-weight: bold;
    text-align: left;
    cursor: default;
}

.videoFooter *,
.remoteVideoFooter * {
    display: inline;
    padding: 10px;
    border: none;
    color: var(--text-color);
}

/*--------------------------------------------------------------
# Avatar Image on video OFF
--------------------------------------------------------------*/

.videoAvatarImage {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    width: 15vh;
    height: 15vh;
    margin: auto;
}

/*--------------------------------------------------------------
# Video View Options
--------------------------------------------------------------*/

.mirror {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

video {
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius);
    object-fit: cover;
}

video:fullscreen {
    border: var(--border-color);
    object-fit: contain;
}

video::-webkit-media-controls {
    display: none !important;
}

/*--------------------------------------------------------------
# My settings
--------------------------------------------------------------*/

#settings {
    position: absolute;
    z-index: 7;
    top: 0px;
    right: 0px;
    display: none;
    background: var(--primary-color);
    width: var(--settings-width);
    height: 100%;
    padding: 20px;
    border: var(--border);
    border-radius: var(--border-radius) 0% 0%;
    overflow: auto;
}

#settings button {
    display: inline;
    border: none;
    float: right;
    background: transparent;
    color: var(--text-color);
    font-size: 1rem;
}

#settings label {
    display: inline-block;
    margin-right: 10px;
    margin-top: 15px;
    font-weight: bold;
    color: var(--text-color);
}

#settings label i {
    margin-right: 5px;
}

#settings select {
    margin-top: 15px;
    width: 100%;
    height: 40px;
    max-width: 280px;
    border-radius: 5px;
    background-color: var(--secondary-color);
    color: var(--text-color);
    font-size: 0.9rem;
    cursor: pointer;
}

/*--------------------------------------------------------------
# My settings table
--------------------------------------------------------------*/

#settingsTable {
    margin-top: 20px;
    width: 100%;
    color: var(--text-color);
    color: #fff;
    border-collapse: collapse;
    border-style: hidden;
}
#settingsTable td,
th {
    padding: 10px;
    text-align: left;
    border: var(--border);
}
#settingsTable tr td span i {
    margin-right: 5px;
}

/*--------------------------------------------------------------
# Swal custom
--------------------------------------------------------------*/

.swal2-title,
.swal2-content {
    background-color: var(--panel-color) !important;
    color: var(--text-color) !important;
    text-align: center;
}

.swal2-html-container {
    background-color: var(--panel-color) !important;
    color: var(--text-color) !important;
}

.swal2-popup {
    border: var(--border) !important;
    background-color: var(--primary-color) !important;
}

.swal2-timer-progress-bar-container {
    background: #376df9;
}

/*--------------------------------------------------------------
# Toggle button 
# https://danklammer.com/articles/simple-css-toggle-switch/
--------------------------------------------------------------*/

.toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 55px;
    height: 24px;
    display: inline-block;
    position: relative;
    border-radius: 50px;
    overflow: hidden;
    outline: none;
    border: var(--border);
    cursor: pointer;
    background-color: #000000;
    transition: background-color ease 0.3s;
}

.toggle:before {
    content: 'on off';
    display: block;
    position: absolute;
    z-index: 2;
    width: 20px;
    height: 18px;
    background: #fff;
    left: 2px;
    top: 2px;
    border-radius: 50%;
    font: 10px/20px Helvetica;
    text-transform: uppercase;
    font-weight: bold;
    text-indent: -25px;
    word-spacing: 35px;
    color: #fff;
    text-shadow: -1px -1px rgba(0, 0, 0, 0.15);
    white-space: nowrap;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
}

.toggle:checked {
    background-color: green;
}

.toggle:checked:before {
    left: 32px;
}

/*--------------------------------------------------------------
# Google Translate
--------------------------------------------------------------*/

.skiptranslate iframe,
.goog-te-banner-frame.skiptranslate {
    display: none !important;
    position: absolute !important;
    top: -10000px !important;
}

#google_translate_element select {
    background: var(--secondary-color) !important;
    color: var(--text-color) !important;
}

/*--------------------------------------------------------------
# FadeIn class effect
--------------------------------------------------------------*/

.fadeIn {
    -webkit-animation: fadeIn ease-in 1;
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/*--------------------------------------------------------------
# Pulse class effect
--------------------------------------------------------------*/

.pulsate {
    animation: pulsate 3s ease-out;
    animation-iteration-count: infinite;
    -webkit-animation: pulsate 3s ease-out;
    -webkit-animation-iteration-count: infinite;
    opacity: 0.5;
}

@-webkit-keyframes pulsate {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

@keyframes pulsate {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

@-webkit-keyframes pulsate {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

/*--------------------------------------------------------------
# Common
--------------------------------------------------------------*/

button {
    cursor: pointer;
}

.hidden {
    visibility: hidden;
}

.show {
    visibility: visible;
}

.center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
}

hr {
    margin-top: 25px;
    border: 1px solid grey;
}

#masterModeLabel {
    display: inline-block;
    margin-top: 15px;
    margin-right: 10px;
    font-weight: bold;
    color: var(--text-color); /* Usa el color de texto definido en tus variables CSS */
}

/*
z-index:
    0 waitingDivContainer
    1 video avatar img
    2 videoHeader
        - full screen mode
        - video status
        - audio status
    3 videoFooter
        - peer name
    ------------------
    4 myVideoContainer
    5 remoteVideoContainer
    6 main buttons
    7 settings
    8 chat
*/


::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background: radial-gradient(#393939, #000000);
    border-radius: 5px;
    cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
    background-color: radial-gradient(#393939, #000000);
}

::-webkit-scrollbar-track {
    background: #1a1b1f;
}
